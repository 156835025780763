<template>
  <div class="filter">
    <div class="content">
      <div class="list">
        <filter-item
          name="一级分类"
          :active="classifyActive1"
          :list="classifyList1"
          :change="classifyChange1"
        />
        <filter-item
          name="二级分类"
          :active="classifyActive2"
          :list="classifyList2"
          :change="classifyChange2"
        />
        <filter-item
          name="三级分类"
          :active="classifyActive3"
          :list="classifyList3"
          :change="classifyChange3"
        />
        <!-- <filter-item
          name="交易模式"
          :active="yzOrderAssignmentType"
          :list="jymsList"
          :change="jymsChange"
        /> -->
        <filter-item
          name="到期时间"
          :active="overDate"
          :list="dqsjList"
          :change="dqsjChange"
        />
        <filter-item
          name="预算金额"
          :active="budgetPrice"
          :list="ysjeList"
          :change="ysjeChange"
        />
        <filter-item
          name="工艺"
          :active="yzCraftTypes"
          :list="gyList"
          :change="gyChange"
        />
        <filter-item
          name="投标状态"
          :active="baojiaCount"
          :list="tbztList"
          :change="tbztChange"
        />
        <filter-item
          name="商家类型"
          :active="yzUserTypes"
          :list="sjlxList"
          :change="sjlxChange"
        />
        <filter-item
          name="需求状态"
          :active="yzTaskStatus"
          :list="xqztList"
          :change="xqztChange"
        />
        <filter-item
          name="生产数量"
          :active="taskNum"
          :list="scslList"
          :change="scslChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import filterItem from "./filter-item";
export default {
  components: {
    filterItem,
  },
  props: {
    gradeActives: {
      default: "-1",
      type: String,
    },
  },
  data() {
    return {
      overDate: "-1",
      dqsjList: [],
      baojiaCount: "-1",
      tbztList: [],
      taskNum: "-1",
      scslList: [],
      yzTaskStatus: "-1",
      xqztList: [],
      yzUserTypes: "-1",
      sjlxList: [],
      yzCraftTypes: "-1",
      gyList: [],
      budgetPrice: "-1",
      ysjeList: [],
      yzOrderAssignmentType: "-1",
      jymsList: [],
      classifyList1: [],
      classifyActive1: "-1",
      classifyList2: [],
      classifyActive2: "-1",
      classifyList3: [],
      classifyActive3: "-1",
    };
  },
  methods: {
    ysjeChange(item) {
      this.budgetPrice = item.dictValue;
      this.change();
    },
    gyChange(item) {
      this.yzCraftTypes = item.dictValue;
      this.change();
    },
    sjlxChange(item) {
      this.yzUserTypes = item.dictValue;
      this.change();
    },
    scslChange(item) {
      this.taskNum = item.dictValue;
      this.change();
    },
    xqztChange(item) {
      this.yzTaskStatus = item.dictValue;
      this.change();
    },
    tbztChange(item) {
      this.baojiaCount = item.dictValue;
      this.change();
    },
    jymsChange(item) {
      this.yzOrderAssignmentType = item.dictValue;
      this.change();
    },
    dqsjChange(item) {
      this.overDate = item.dictValue;
      this.change();
    },
    change() {
      $(".filter .list .item").each(function () {
        if ($(this).find(".item-list-main").height() > 40) {
          $(this).find(".down").show();
        }
      });
      let yzTaskCategoryIds = "";
      if (this.classifyActive3 != "-1") {
        yzTaskCategoryIds = this.classifyActive3;
      } else if (this.classifyActive2 != "-1") {
        yzTaskCategoryIds = this.classifyActive2;
      } else if (this.classifyActive1 != "-1") {
        yzTaskCategoryIds = this.classifyActive1;
      }
      this.$emit("change", {
        yzTaskCategoryIds,
        yzOrderAssignmentType: this.isP(this.yzOrderAssignmentType),
        budgetPrice: this.isP(this.budgetPrice),
        yzCraftTypes: this.isP(this.yzCraftTypes),
        yzUserTypes: this.isP(this.yzUserTypes),
        yzTaskStatus: this.isP(this.yzTaskStatus),
        taskNum: this.isP(this.taskNum),
        baojiaCount: this.isP(this.baojiaCount),
        overDate: this.isP(this.overDate),
      });
    },
    isP(item) {
      return item == "-1" ? "" : item;
    },
    async getDict() {
      //交易模式
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_order_assignment_type.json")
        .then((res) => {
          this.jymsList = [{ dictValue: "-1", dictLabel: "全部" }, ...res];
        });
      //到期时间
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_over_data.json")
        .then((res) => {
          this.dqsjList = [{ dictValue: "-1", dictLabel: "全部" }, ...res];
        });
      //预算金额
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_order_assignment_money_type.json")
        .then((res) => {
          this.ysjeList = [{ dictValue: "-1", dictLabel: "全部" }, ...res];
        });
      //工艺
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_craft_types.json")
        .then((res) => {
          this.gyList = [{ dictValue: "-1", dictLabel: "全部" }, ...res];
        });
      //商家类型
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_company_type.json")
        .then((res) => {
          this.sjlxList = [{ dictValue: "-1", dictLabel: "全部" }, ...res];
        });
      //需求状态
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_task_staus.json")
        .then((res) => {
          this.xqztList = [{ dictValue: "-1", dictLabel: "全部" }, ...res];
        });
      //生产数量
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_production_quantity.json")
        .then((res) => {
          this.scslList = [{ dictValue: "-1", dictLabel: "全部" }, ...res];
        });
      //投标状态
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_task_staus.json")
        .then((res) => {
          this.tbztList = [{ dictValue: "-1", dictLabel: "全部" }, ...res];
        });
      //获取服务分类
      this.getClassifyData();
    },
    classifyChange1(item) {
      this.classifyActive1 = item.id;
      this.classifyList2 =
        item.id != "-1" && item.list && item.list.length ? item.list : [];
      this.classifyList3 = [];
      this.classifyActive2 = "-1";
      this.change();
    },
    classifyChange2(item) {
      this.classifyActive2 = item.id;
      this.classifyList3 =
        item.id != "-1" && item.list && item.list.length ? item.list : [];
      this.change();
    },
    classifyChange3(item) {
      this.classifyActive3 = item.id;
      this.change();
    },
    getClassifyData() {
      this.$sapi.get("/api/v1/getYzTaskCategoryList.json").then((res) => {
        let _json = {};
        let pList = [];
        let list = res.filter((item) => {
          if (item.parentCode == "0") {
            _json[item.id] = [];
            pList.push(item);
          }
          return item.parentCode != "0";
        });
        list.forEach((item) => {
          if (_json[item.parentCode]) _json[item.parentCode].push(item);
        });
        this.classifyList1 = [
          { id: "-1", name: "全部" },
          ...pList.map((item) => {
            if (_json[item.id] && _json[item.id].length) {
              item.list = [{ id: "-1", name: "全部" }, ..._json[item.id]];
              item.list = item.list.map((items) => {
                if (_json[items.id] && _json[items.id].length) {
                  items.list = [{ id: "-1", name: "全部" }, ..._json[items.id]];
                }
                return items;
              });
            }
            return item;
          }),
        ];
        //展开收缩
        this.$nextTick(() => {
          $(".filter .list .item").each(function () {
            if ($(this).find(".item-list-main").height() > 40) {
              $(this).find(".down").show();
            }
          });
          $(".filter .list .item .down").click(function () {
            if ($(this).hasClass("on")) {
              $(this).removeClass("on").find("font").html("展开");
              $(this)
                .find(".el-icon-caret-top")
                .removeClass("el-icon-caret-top")
                .addClass("el-icon-caret-bottom");
              $(this).parent().height(25);
            } else {
              $(this).addClass("on").find("font").html("收缩");
              $(this)
                .find(".el-icon-caret-bottom")
                .removeClass("el-icon-caret-bottom")
                .addClass("el-icon-caret-top");
              $(this)
                .parent()
                .height($(this).parent().find(".item-list-main").height());
            }
          });
        });
        console.log(this.classifyList1);
      });
    },
  },
  mounted() {
    //获取字典
    this.getDict();
  },
};
</script>